.about-body{
    height: 84vh;
}


.about-body p{
    margin-bottom: 1%;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
    font-size: 2vw;
    background-color: rgba(149, 67, 220, 0.7);
    border-radius: 50px;
    padding: 35px;
    color: white;
}

.about-body h2{
    text-align: center;
    font-size: 3vw;
    color: white;
}

@media screen and (max-width: 600px) {

    .about-body p{
        font-size: 4vw;
        margin-left: auto;
        margin-right: auto;
    }
    
    .about-body h2{
        font-size: 7vw;
    }
    
    .about-body h1{
        font-size: 5.5vw;
    }
    
    .about-body li{
        margin-left: 5%;
        font-size: 3.8vw;
    }

}