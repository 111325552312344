body {
  font-family: "Fredoka";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  --color-blue: #60A3C8;
  --color-light-green: #C6C012;
  --color-orange: #E8A04E;
  --color-yellow: #FCD146;
  --color-red: #DD6157;
  --color-dark-green: #034732;
}