.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 90px;
    width: 100%;
    padding: 0.8rem 0rem;
    background-color:rgba(149, 67, 220, 0.7); 
    display: flex;
    color: var(--color-yellow);
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    border-radius: 10px;
  }
  
  .brand-name {
    color: white; 
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.3rem;
    margin-left: 0.8rem;
  }

  .brand-name img{
    width: 100px;
    height: auto;
  }

  .brand-name h2{
    font-size: 3.5vw;
    margin-left: 1rem;
  }
  
  .navigation-menu {
    margin-left: auto;
    padding-right: 2rem;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    font-size: 2vw;
    text-decoration: none;
    color: white;
    display: block;
    width: 100%;
  }

  .navigation-menu li a:hover {
    color: var(--color-blue);
  } 
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #BFCDDB;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    color: var(--color-dark-green);
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    font-size: larger;
  }
  
  .hamburger:hover {
    background-color: var(--color-blue);
  }
  
  @media screen and (max-width: 600px) {

    .hamburger {
      display: block;
    }

    .brand-name h2{
        font-size: 8vw;
        margin-left: 0.6rem;
      }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
      height: calc(100vh - 90px);
      background-color: #BFCDDB;
      display: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu li a {
      font-size: larger;
      color: white;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navigation-menu li:hover {
      background-color: rgba(149, 67, 220, 0.7);
    }
  
    .navigation-menu.expanded ul {
      display: block;
      z-index: 4;
    }

    .navigation{
      height: 70px;
    }

    .brand-name img{
      width: 80px;
      height: auto;
    }
  }
  