.resources-body {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    width: 100%;
    height: 84vh;
}

.resources-body h2{
    text-align: center;
    font-size: 3vw;
    color: white;
}

.grid-container-resources {
    display: grid;
    position: relative;
    justify-self: center;
    justify-items: center;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    width: fit-content;
    height: fit-content;
    margin-top: -10%;
}

.grid-child-resources{
    padding-right: 20%;
    padding-left: 20%;
    width: fit-content;
    height: fit-content;
}

.iconBtn {
    border-radius: 100px;
    background-color: #BFCDDB;
	color: black;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
    width: 10vw;
    height: 10vw;
    font-size: 4.5vw;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.iconBtn:hover{
    background-color: white; 
}

.grid-child-resources h3{
    color: white;
    text-align: center;
    font-size: 1.5vw;
}



@media screen and (max-width: 600px){
    
    .resources-body h2{
        font-size: 7vw;
    }

    .grid-container-resources {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto auto auto auto auto;
        width: auto;
        height: auto;
        margin-top: 0%;
    }
    
    .grid-child-resources{
        padding-right: 0%;
        padding-left: 0%;
        width: auto;
        height: auto;
    }
    
    .iconBtn {
        width: 20vw;
        height: 20vw;
        font-size: 10vw;
    }
    
    .grid-child-resources h3{
        color: white;
        text-align: center;
        font-size: 5vw;
    }
    #buttonOne{
        grid-row: 1;
    }
    #textOne{
        grid-row: 2;
    }
    #buttonTwo{
        grid-row: 3;
    }
    #textTwo{
        grid-row: 4;
    }
    #buttonThree{
        grid-row: 5;
    }
    #textThree{
        grid-row: 6;
    }
} 