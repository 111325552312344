.privacy-section{
    background-color: rgba(149, 67, 220, 0.7);
    margin-bottom: 2.5%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 50px;
    padding: 10px;
}

.privacy-body h2{
    text-align: center;
    font-size: 3vw;
    color: white;
}

.privacy-body p{
    margin-top: 2,5%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1.5vw;
    color: white; 
}

.privacy-body h1{
    font-size: 2vw;
    color: white;
}

.privacy-body li{
    margin-left: 3%;
    font-size: 1.2vw;
    color: white;
}

#privacyCenter {
    text-align: center;
}

#contactInfo{
    margin-left: 3%;
}

@media screen and (max-width: 600px){
    
    .privacy-body h2{
        font-size: 7vw;
    }

    .privacy-body p{
        font-size: 4vw;
        height: auto;
    }
    
    .privacy-body h1{
        font-size: 5.5vw;
    }
    
    .privacy-body li{
        margin-left: 5%;
        font-size: 3.8vw;
    }
    
    #privacyCenter {
        text-align: center;
    }
    
    #contactInfo{
        margin-top: 2%;
        margin-left: 5%;
    }

}